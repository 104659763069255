import { useState, useEffect } from "react";
import api from "../services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderAdmin from "../components/HeaderAdmin";
import backArrow from "../images/back_arrow.svg";
import Moment from "react-moment";
import Table from "../components/Table";

export default function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({ settings: {} });
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, [id]);

  const fetchUser = async () => {
    if (id !== "create") {
      let user = await api.getUserAdmin(id);
      if (user.status === 200) {
        setUser(user.data);
        setPosts(user.data.posts);
      }
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    user.settings = { ...user.settings, ...newObj };
    setUser({ ...user });
  };

  const save = async () => {
    let data = await api.saveUser(user);
    if (data.status === 200) {
      navigate("/");
    }
  };

  const remove = async () => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removeUser(user._id);
      if (data.status === 200) {
        navigate("/");
      }
    }
  };

  const config = {
    columns: [
      {
        key: "title",
        header: "Title",
        template: (item) => <Link to={"/user/" + item._id}>{item.title}</Link>,
      },
      {
        header: "published Date",
        key: "publishedDate",
        template: (item) => <>{item.publishedDate ? <Moment format="MM-DD-YYYY">{item.publishedDate}</Moment> : ""}</>,
      },
    ],
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="edit-post-container">
        <>
          <h2>
            <Link to="/" className="back-link">
              <img style={{ height: 30, marginBottom: -5 }} src={backArrow} alt="back" />
              Post Edit
            </Link>
          </h2>

          <div className="btn-bar">
            <div className="btn-bar-left">
              <button onClick={save}>Save</button>
            </div>
            <div className="btn-bar-right">
              {user._id && (
                <button onClick={remove} className="dark">
                  Delete
                </button>
              )}
            </div>
          </div>

          <div>
            <h2>User Info</h2>

            <div className="label-row">
              <label>Email</label>
              {user.email}
            </div>
            <div className="label-row">
              <label>Registration Date</label>
              <Moment format="MM-DD-YYYY">{user.registrationDate}</Moment>
            </div>
            <div className="label-row">
              <label>Send Email</label>
              <input
                type="checkbox"
                checked={user.settings.sendEmail ? "checked" : ""}
                onClick={(e) => {
                  setValue("sendEmail", e.target.checked);
                }}
              ></input>
            </div>

            <h2>Settings</h2>
            <div className="label-row">
              <label>Blog Description</label>
              {user.settings && user.settings.blogDescription}
            </div>
            <div className="label-row">
              <label>Publish Mode</label>
              {user.settings && user.settings.publishMode}
            </div>
            <div className="label-row">
              <label>Scheduled Publishing</label>
              {user.settings && user.settings.scheduledPublishing}
            </div>

            <h2>Posts</h2>

            <div className="table-container">
              <Table config={config} data={posts}></Table>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
