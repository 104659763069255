import { useState, useEffect } from "react";
import { useAuth } from "../context/useAuth";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

export default function Table({ data, fetchFn, config, filter }) {
  const [sortKey, setSortKey] = useState();
  const [scroll, setScroll] = useState();
  const [list, setList] = useState(data || []);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState("");
  const auth = useAuth();
  const pageSize = 40;

  useBottomScrollListener(() => {
    if (window.pageYOffset !== scroll) {
      setScroll(window.pageYOffset);
      if (!data) loadMore();
    }
  });

  const fetchData = async (currentPage) => {
    let result = await fetchFn({ pageSize, currentPage, sortKey, sortDirection, filter });

    if (result.status !== 200) return;

    setList((list) => list.concat(result.data));

    if (result.data.length === pageSize) {
      setShowLoadMore(true);
    } else {
      setShowLoadMore(false);
    }
  };

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
    fetchData(currentPage + 1);
  };

  useEffect(() => {
    if (data) {
      setList(data);
    } else {
      setList([]);
    }

    setCurrentPage(0);
    if (auth.user && fetchFn) fetchData(0, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey, sortDirection, filter, auth.user, data]);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let filteredData = list.filter((item, key) => {
    if (fetchFn) return true;
    for (let filterKey in filter) {
      if (filter[filterKey] === "true" || filter[filterKey] === "false") {
        if (!item[filterKey]) item[filterKey] = false;
        if (filter[filterKey] && item[filterKey].toString() !== filter[filterKey].toString()) {
          return false;
        }
      } else {
        if (!filter[filterKey]) continue;
        if (
          !item[filterKey] ||
          (item[filterKey].toLowerCase && item[filterKey].toLowerCase().indexOf(filter[filterKey].toLowerCase()) === -1)
        ) {
          return false;
        }
      }
    }
    return true;
  });

  const headerRender = (
    <tr>
      {config.columns.map((col, indexHeader) => (
        <th
          onClick={() => setSortKeyFn(col.sort || col.key)}
          key={"header_" + indexHeader}
          className={
            (col.sort || col.key ? "sortable " : "") +
            (sortKey
              ? col.sort
                ? sortKey === col.sort
                  ? "sort " + sortDirection
                  : ""
                : sortKey === col.key
                ? "sort " + sortDirection
                : ""
              : "")
          }
        >
          {col.header || capitalize(col.key)}
        </th>
      ))}
    </tr>
  );

  const setSortKeyFn = (key) => {
    if (!key) return;
    if (sortKey === key) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
      } else {
        setSortDirection("asc");
      }
    } else {
      setSortDirection("asc");
    }
    setSortKey(key);
  };

  const sortFn = (a, b) => {
    if (!sortDirection) return 0;
    if (a === b) return 0;

    if (sortDirection === "asc") {
      return getAttr(a, sortKey) > getAttr(b, sortKey) ? 1 : -1;
    } else {
      return getAttr(a, sortKey) > getAttr(b, sortKey) ? -1 : 1;
    }
  };

  const getAttr = (obj, key) => {
    let keys = key.split(".");
    let val = obj;
    for (let k of keys) {
      if (val) val = val[k];
      if (val === undefined) return;
    }

    return val;
  };

  const rowsRender = filteredData.sort(sortFn).map((row, indexRow) => (
    <tr key={"row_" + indexRow}>
      {config.columns.map((col, indexCol) => (
        <td key={"row_" + indexCol}>{col.template ? col.template(row) : row[col.key]}</td>
      ))}
    </tr>
  ));

  return (
    <table>
      <thead>{headerRender}</thead>
      <tbody>{rowsRender}</tbody>
      {showLoadMore && (
        <tfoot onClick={loadMore}>
          <tr>
            <td colSpan={config.columns.length} style={{ cursor: "pointer" }}>
              Load more...
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
