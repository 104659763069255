import { Link } from "react-router-dom";
import logo from "../images/logo.svg";

// Context
import { useAuth } from "../context/useAuth";

export default function HeaderAdmin() {
  const auth = useAuth();

  return (
    <div className="navbar navbar-admin">
      <div className="navbar-left">
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="Logo"></img>
          <span className="navbar-logo-text">CreateBlog AI Admin</span>
        </Link>
      </div>

      <div className="navbar-center"></div>

      <div className="navbar-right">
        <div className="navbar-btn">
          <Link to="/">Users</Link>
        </div>
        <div className="navbar-btn">
          <Link to="/posts">Blog Posts</Link>
        </div>

        <div className="navbar-btn">
          <a href="/" onClick={auth.logout}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}
