import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Pages
import BlogList from "./pages/BlogList";
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import UserEdit from "./pages/UserEdit";

// Context
import { useAuth } from "./context/useAuth";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {auth.user ? (
        <>
          <Route path="/" element={<UserList />} />
          <Route path="/user/:id" element={<UserEdit />} />
          <Route path="/posts" element={<BlogList />} />
        </>
      ) : (
        <Route path="/" element={<Login />} />
      )}
    </Routes>
  );
}
