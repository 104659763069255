import React from "react";
import Routes from "./Routes";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="main-container">
      <ProvideAuth>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ProvideAuth>
    </div>
  );
}

export default App;
