import { useState } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "../components/Table";

export default function UserList() {
  const [filter, setFilter] = useState({});

  const config = {
    columns: [
      {
        key: "email",
        header: "Email",
        template: (item) => <Link to={"/user/" + item._id}>{item.email}</Link>,
      },
      {
        header: "Registration Date",
        key: "registrationDate",
        template: (item) => <>{item.registrationDate ? <Moment format="MM-DD-YYYY">{item.registrationDate}</Moment> : ""}</>,
      },
      {
        header: "Blog Description",
        key: "postCount",
        template: (item) => <div>{item.settings && item.settings.blogDescription}</div>,
      },
      {
        header: "Post Created",
        key: "postCount",
        template: (item) => <div>{item.postCount}</div>,
      },
    ],
  };

  const setFilterValue = (key) => {
    return (e) => {
      let set = {};
      set[key] = e.target.value;
      setFilter({ ...filter, ...set });
    };
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Users List</h2>

        <div className="filter-container">
          <div className="filter-row">
            <div className="filter-label">E-mail</div>
            <div className="filter-data">
              <input onChange={setFilterValue("email")} />
            </div>
          </div>
        </div>

        <div className="table-container">
          <Table config={config} filter={filter} fetchFn={api.getUsers}></Table>
        </div>
      </div>
    </>
  );
}
