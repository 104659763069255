import { useEffect, useState } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";
import EditPostModal from "../components/EditPostModal";
import { getUrlPost } from "../services/utils";

export default function AdminBlog() {
  const [post, setPost] = useState();
  const [posts, setPosts] = useState([]);
  const [showEditModal, setShowEditModal] = useState();
  // const [success, setSuccess] = useState();
  // const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, []);

  const fetchPost = async () => {
    let posts = await api.getBlogPosts();
    if (posts.status === 200) setPosts(posts.data);
  };

  const addPost = () => {
    setPost({});
    setShowEditModal(true);
  };

  const editPost = (post) => {
    setPost(post);
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
    fetchPost();
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Blog</h2>

        <div className="btn-bar">
          <button onClick={addPost}>Add Article</button>
        </div>

        <table>
          <thead>
            <tr>
              <td>Enabled</td>
              <td>Title</td>
              <td>Preview</td>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, i) => (
              <tr key={i}>
                <td className="product-views">
                  <input type="checkbox" checked={post.enabled} disabled></input>
                </td>
                <td style={{ maxWidth: 400 }} onClick={(e) => editPost(post)}>
                  {post.title}
                </td>
                <td>
                  <a rel="noreferrer" href={getUrlPost(post)} target="_blank" referrerPolicy="no-referrer-when-downgrade">
                    Preview
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditModal && <EditPostModal postId={post._id} close={closeModal}></EditPostModal>}
      </div>
    </>
  );
}
