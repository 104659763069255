import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (email, password) => {
    return api.post(`/api/adminUsers/login`, { email, password });
  };

  const getUser = () => {
    return api.get(`/api/adminUsers/getUser`, {});
  };

  const removePost = (id) => {
    return api.delete(`/api/blogPostPublic/${id}`);
  };

  const getBlog = (id) => {
    return api.get(`/api/blogPostPublic/${id}`);
  };

  const savePost = (post) => {
    return api.post(`/api/blogPostPublic/${post._id}`, post);
  };

  const getBlogPosts = () => {
    return api.get(`/api/blogPostPublic`);
  };

  const savePinterestCode = (code) => {
    return api.post(`/api/adminUsers/pinterestCode`, { code });
  };

  const publishAll = () => {
    return api.post(`/api/product/publishAll`);
  };

  const uploadPhoto = async (file, params) => {
    let formData = new FormData();
    formData.append("params", JSON.stringify(params));
    formData.append("file", file);

    return api.post(`/api/blogPostPublic/uploadPhotoAdmin`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
    });
  };

  const getUsers = (params) => {
    return api.get(`/api/users`, {
      filter: params.filter,
      pageSize: params.pageSize,
      currentPage: params.currentPage,
      sortKey: params.sortKey,
      sortDirection: params.sortDirection,
    });
  };

  const getUserAdmin = (id) => {
    return api.get(`/api/users/${id}`);
  };

  const removeUser = (id) => {
    return api.delete(`/api/users/${id}`);
  };

  const saveUser = (user) => {
    return api.post(`/api/users/${user._id}`, user);
  };

  // return functions for interface
  return {
    setToken,
    login,
    getUser,
    uploadPhoto,
    getBlogPosts,
    savePinterestCode,
    publishAll,
    getBlog,
    removePost,
    savePost,
    getUsers,
    getUserAdmin,
    removeUser,
    saveUser,
  };
};

// Creates a singleton
export default createApi();
